import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: {},
});

new Vue({
    i18n,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');

export {i18n}
