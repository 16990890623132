import Vue from 'vue'
import VueRouter from 'vue-router'
import {i18n} from '@/main.js';
import {loadLanguageAsync} from '@/setup/i18n-setup';

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "not_found" */ '@/views/notFound/NotFound.vue'),
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'home.page_title',
            metaDescription: 'home.meta_description',
            langFiles: ['home', 'news', 'features'],
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: 'about.page_title',
            metaDescription: 'about.meta_description',
            langFiles: ['about'],
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/About.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: 'contact.page_title',
            metaDescription: 'contact.meta_description',
            langFiles: ['contact'],
        },
        component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/Contact.vue'),
    },
    {
        path: '/global_goals',
        name: 'GlobalGoals',
        meta: {
            title: 'global_goals.page_title',
            metaDescription: 'global_goals.meta_description',
            langFiles: ['global_goals'],
        },
        component: () => import(/* webpackChunkName: "global_goals" */ '@/views/global_goals/GlobalGoals.vue'),
    },
    {
        path: '/gdpr',
        name: 'Gdpr',
        meta: {
            title: 'gdpr.page_title',
            metaDescription: 'gdpr.meta_description',
            langFiles: ['gdpr'],
        },
        component: () => import(/* webpackChunkName: "global_goals" */ '@/views/gdpr/Gdpr.vue'),
    },
    {
        path: '/features',
        name: 'Features',
        meta: {
            title: 'features.page_title',
            metaDescription: 'features.meta_description',
            langFiles: ['features'],
        },
        component: () => import(/* webpackChunkName: "features" */ '@/views/features/Features.vue'),
    },
    {
        path: '/values',
        name: 'Values',
        meta: {
            title: 'values.page_title',
            metaDescription: 'values.meta_description',
            langFiles: ['values'],
        },
        component: () => import(/* webpackChunkName: "values" */ '@/views/values/Values.vue'),
    },
    {
        path: '/news/:page?',
        name: 'News',
        meta: {
            title: 'news.page_title',
            metaDescription: 'news.meta_description',
            langFiles: ['news'],
        },
        component: () => import(/* webpackChunkName: "news" */ '@/views/news/News.vue'),
    },
    {
        path: '/privacy',
        name: 'Privacy',
        meta: {
            title: 'privacy.page_title',
            metaDescription: 'privacy.meta_description',
            langFiles: ['privacy'],
        },
        component: () => import(/* webpackChunkName: "privacy" */ '@/views/privacy/Privacy.vue'),
    }
]

let transition = 0;
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {selector: to.hash};
        }

        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    if (transition === 0) {
        i18n.locale = JSON.parse(localStorage.getItem('lang'))?.value || i18n.fallbackLocale;
    }

    loadLanguageAsync(['main'].concat(to.meta?.langFiles || [])).then(() => {
        router.app._loaded = true;
        document.title = i18n.t(to.meta.title);
        document.querySelector('meta[name="description"]')?.setAttribute('content', i18n.t(to.meta.metaDescription));
        next();
    });
});

router.afterEach(() => {
    transition++;
});

export default router
