<template>
  <v-app>
    <div id="badge-overlay" v-if="$route.name == 'Home'">
      <div id="stores-container">
        <a id="app-store" href="https://apps.apple.com/nb/app/easy-work/id1294617602?itsct=apps_box_badge&amp;itscg=30200" target="_blank">
          <img v-if="selectedLanguage.value === 'en-US'" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1507766400" alt="Download on the App Store">
          <img v-if="selectedLanguage.value === 'nb-NO'" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/no-no?size=250x83&amp;releaseDate=1507766400" alt="Download on the App Store">
          <img v-if="selectedLanguage.value === 'fr'" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-fr?size=250x83&amp;releaseDate=1507766400" alt="Download on the App Store">
        </a>

        <a id="play-store" href='https://play.google.com/store/apps/details?id=com.easyatwork.mobileapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
          <img v-if="selectedLanguage.value === 'en-US'" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' alt='Get it on Google Play'/>
          <img v-if="selectedLanguage.value === 'nb-NO'" src='https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png' alt='Get it on Google Play'/>
          <img v-if="selectedLanguage.value === 'fr'" src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png' alt='Get it on Google Play'/>
        </a>
      </div>
    </div>

    <v-navigation-drawer dark v-model="drawer" app right disable-resize-watcher>
      <v-treeview :items="menuItems" hoverable rounded open-on-click>
        <template v-slot:prepend="{ item }">
          <router-link v-if="!item.children" :to="item.to">
            <v-icon>{{ 'mdi-' + item.icon }}</v-icon>
          </router-link>

          <v-icon v-if="item.children">{{ 'mdi-' + item.icon }}</v-icon>
        </template>

        <template v-slot:label="{ item }">
          <router-link v-if="!item.children" :to="item.to">{{ $t(item.name) }}</router-link>
          <span v-if="item.children">{{ $t(item.name) }}</span>
        </template>
      </v-treeview>

      <v-divider></v-divider>

      <v-treeview :items="loginItem" hoverable rounded open-on-click>
        <template v-slot:prepend="{ item }">
          <v-icon>{{ 'mdi-' + item.icon }}</v-icon>
        </template>

        <template v-slot:label="{ item }">
          <a :href="item.link" target="_blank" rel="noopener">{{ $t(item.name) }}</a>
        </template>
      </v-treeview>

      <v-treeview :items="langItems" hoverable rounded open-on-click>
        <template v-slot:prepend="{ item, leaf }">
          <v-icon v-if="leaf" @click="changeLanguage(item)">{{ 'mdi-' + item.icon }}</v-icon>
          <v-icon v-if="!leaf">{{ 'mdi-' + item.icon }}</v-icon>
        </template>

        <template v-slot:label="{ item, leaf }">
          <span v-if="leaf" @click="changeLanguage(item)">{{ item.name }}</span>
          <span v-if="!leaf">{{ item.name }}</span>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-app-bar app dark color="primary" class="darken-1">
      <div class="d-flex align-center mr-5">
        <router-link to="/">
          <v-img min-width="32" max-width="32" alt="easy at work logo" src="../src/assets/at.svg"/>
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <div v-for="(item, index) in menuItems" :key="index" class="hidden-sm-and-down">
        <v-skeleton-loader v-if="!loaded()" type="button"></v-skeleton-loader>

        <v-btn v-if="!item.children && loaded()" text :to="item.to">{{ $t(item.name) }}</v-btn>

        <v-menu v-if="item.children && loaded()" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <span>{{ $t(item.name) }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(child, index) in item.children" :key="index" :to="child.to">
              <v-list-item-title>{{ $t(child.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>

      <v-skeleton-loader v-if="!loaded()" type="button"></v-skeleton-loader>

      <v-btn v-show="loaded()" class="hidden-sm-and-down" :href="loginItem[0].link" target="_blank" rel="noopener" text>
        <v-icon left>{{ 'mdi-' + loginItem[0].icon }}</v-icon>
        <span>{{ $t(loginItem[0].name) }}</span>
      </v-btn>

      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="hidden-sm-and-down" text v-bind="attrs" v-on="on">
            <span>{{ selectedLanguage.iso_6392 }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(lang, index) in languages" :key="index" @click="changeLanguage(lang)">
            <v-list-item-title>{{ lang.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main class="primary lighten-5 mt-sm-3 mt-md-6 mt-lg-8">
      <router-view></router-view>
    </v-main>

    <v-footer v-show="loaded()" dark class="primary darken-1 py-10">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="2" class="d-flex flex-column font-weight-light">
            <span class="font-weight-bold pb-4">{{ $t('main.product') }}</span>
            <router-link to="/features" class="text-decoration-none white--text">{{ $t('main.menu_features') }}</router-link>
            <router-link to="/news" class="text-decoration-none white--text">{{ $t('main.menu_news') }}</router-link>
          </v-col>

          <v-col cols="12" md="2" class="d-flex flex-column font-weight-light">
            <span class="font-weight-bold pb-4">{{ $t('main.company') }}</span>
            <router-link to="/about" class="text-decoration-none white--text">{{ $t('main.menu_about_us') }}</router-link>
            <router-link to="/values" class="text-decoration-none white--text">{{ $t('main.menu_values') }}</router-link>
            <router-link to="/global_goals" class="text-decoration-none white--text">{{ $t('main.menu_global_goals') }}</router-link>
          </v-col>

          <v-col cols="12" md="2" class="d-flex flex-column font-weight-light">
            <span class="font-weight-bold pb-4">{{ $t('main.help') }}</span>
            <a :href="loginItem[0].link" target="_blank" rel="noopener" class="text-decoration-none white--text">{{ $t('main.login') }}</a>
            <a href="mailto:support@easyatwork.com" class="text-decoration-none white--text">{{ $t('main.support') }}</a>
          </v-col>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
          <v-col class="text-center" cols="12" md="4">
            <span class="text-body-2 font-weight-light">
              <router-link to="/privacy" class="white--text">{{ $t('main.privacy_policy') }}</router-link>
            </span>
          </v-col>

          <v-col class="text-center" cols="12" md="4">
            <span class="text-body-2 font-weight-light">{{ $t('main.our_address') }}</span>
          </v-col>

          <v-col class="text-center" cols="12" md="4">
            <span class="text-body-2 font-weight-light">© 2016 — {{ year }} Easy at work AS</span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import {i18n} from '@/main';
import {getAdditionalLangFiles} from '@/setup/i18n-setup';
import {loadLanguageAsync} from '@/setup/i18n-setup';

export default {
  name: 'App',
  methods: {
    loaded: function () {
      return this.$router.app._loaded;
    },
    changeLanguage(lang) {
      this.selectedLanguage = lang;
      localStorage.setItem('lang', JSON.stringify(this.selectedLanguage));

      window.location.reload();
    },
    setLanguage(lang) {
      // Update things that use lang
      this.selectedLanguage = lang;
      i18n.locale = this.selectedLanguage.value;
      localStorage.setItem('lang', JSON.stringify(this.selectedLanguage));
      document.querySelector('html').setAttribute('lang', this.selectedLanguage.value);

      // Load lang files
      let routeLangs = this.$route.meta?.langFiles || [];
      let additionalLangs = getAdditionalLangFiles() || [];

      loadLanguageAsync(['main'].concat(routeLangs).concat(additionalLangs));
    },
  },
  computed: {
    langItems: function () {
      return [
        {
          name: this.selectedLanguage.iso_6392,
          children: this.languages,
        }
      ];
    },
    menuItems() {
      return [
        {
          name: 'main.menu_home',
          to: '/',
          icon: 'home',
        },
        {
          name: 'main.menu_features',
          to: '/features',
          icon: 'sparkles',
        },
        {
          name: 'main.menu_news',
          to: '/news',
          icon: 'newspaper-variant',
        },
        {
          name: 'main.menu_about',
          icon: 'information',
          children: [
            {
              name: 'main.menu_about_us',
              to: '/about',
              icon: 'account',
            }, {
              name: 'main.menu_values',
              to: '/values',
              icon: 'compass',
            },
            {
              name: 'main.menu_global_goals',
              to: '/global_goals',
              icon: 'earth',
            },
            {
              name: 'main.menu_gdpr',
              to: '/gdpr',
              icon: 'security',
            },
          ]
        },
        {
          name: 'main.menu_contact',
          to: '/contact',
          icon: 'send',
        }
      ];
    },
  },
  data: () => ({
    drawer: false,
    selectedLanguage: null,
    useAppStore: false,
    useGooglePlay: false,
    languages: [
      {name: 'English', value: 'en-US', iso_6392: 'ENG'},
      {name: 'Norsk', value: 'nb-NO', iso_6392: 'NOR'},
      {name: 'Français', value: 'fr', iso_6392: 'FRA'},
    ],
    year: new Date().getFullYear(),
    loginItem: [
      {
        name: 'main.login',
        link: 'https://app.easyatwork.com',
        icon: 'login',
      },
    ],
  }),
  beforeMount() {
    let storageLang = JSON.parse(localStorage.getItem('lang'));
    let navigatorLang = this.languages.find(l => l.value === navigator.language);
    let defaultLang = this.languages.find(l => l.value === 'en-US');
    this.setLanguage(storageLang || navigatorLang || defaultLang);
  }
};
</script>

<style scoped>
#badge-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
}

.app-store-link {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  width: 250px;
  height: 83px;
}

.app-store-link > img {
  border-radius: 13px;
  width: 250px;
  height: 83px;
}

#stores-container {
  left: 50px;
  position: absolute;
  bottom: 30px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 20px;
}

#play-store, #app-store {
  display: flex;
  justify-content: center;
  align-items: center;
}

#play-store > img {
  height: 60px;
}

#app-store > img {
  height: 60px;
  padding: 6px 0;
}

@media only screen and (max-width: 600px) {
  #badge-overlay {
    display: flex;
    justify-content: center;
    align-items: end;
  }

  #stores-container {
    position: initial;
    display: flex;
    margin-bottom: 12px;
  }

  #play-store > img {
    height: 50px;
  }

  #app-store > img {
    height: 50px;
    padding: 6px 0;
  }
}

nav a {
  color: white;
  text-decoration: none;
}
</style>
