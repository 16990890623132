import axios from 'axios'
import {i18n} from '@/main.js';

const additionalLangFiles = {};
const loadedItems = {};

export function setAdditionalLangFiles(key, files) {
    additionalLangFiles[key] = new Set(files);
}

export function getAdditionalLangFiles() {
    return Object.values(additionalLangFiles).reduce((acc, langSet) => {
        return acc.concat(Array.from(langSet));
    }, []);
}

export function loadLanguageAsync(files) {
    files = [files].flat();

    let filesToLoad = new Set();

    files.forEach(f => {
        if (loadedItems[i18n.locale]?.[f]) return;

        setLoaded(i18n.locale, f);
        setLoaded(i18n.fallbackLocale, f);

        loadFromStorage();

        filesToLoad.add(`https://www2.easyatwork.com/getTranslation?file=${f}&lang=${i18n.locale}`);
    });

    let promises = Array.from(filesToLoad).map(ftl => {
        return axios.get(ftl).then(handleLoadedlocale);
    }).flat();

    return Promise.all(promises);
}

function loadFromStorage() {
    Object.entries(localStorage).forEach(([key, value]) => {
        if ((/lang:.{2,5}:.+/).test(key)) {
            handleLoadedlocale({
                data: {
                    lang: key.split(':')[1],
                    file: key.split(':')[2],
                    contents: value,
                },
            });
        }
    });
}

function setLoaded(locale, file) {
    loadedItems[locale] = loadedItems[locale] || {};
    loadedItems[locale][file] = true;
}

function handleLoadedlocale(resp) {
    let locale = resp.data.lang;
    let file = resp.data.file;

    i18n.mergeLocaleMessage(locale, {
        [file]: JSON.parse(resp.data.contents),
    });

    try {
        localStorage.setItem(`lang:${locale}:${file}`, resp.data.contents);
    } catch (e) {
        console.error(e);
        localStorage.clear();
    }
}
